import React from 'react'
import { graphql } from 'gatsby'
import useMedia from 'use-media'
import Img from 'gatsby-image'

import { Wrapper, SEO, Swiper, FadeSwiper, Billboard } from '@renderbus/common/components'
import { PromotionList, ActivityBox, BannerContainer } from './promotion.atom'

import Layout from '../molecules/layout'

const SwiperSwitch = ({ children, ...rest }) => {
  const isWide = useMedia({ minWidth: 768 })
  return isWide ? (
    <FadeSwiper swiperHeight='600px' {...rest}>
      {children}
    </FadeSwiper>
  ) : (
    <Swiper {...rest}>{children}</Swiper>
  )
}
class Promotion extends React.PureComponent {
  render() {
    const {
      data,
      location,
      pageContext: { topThreeShare },
    } = this.props

    const bannerList = data.bannerInfo.nodes
    const activityList = data.activity.nodes
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='云渲染活动中心,云渲染充值优惠活动,折扣价格,行业竞赛等-Renderbus云渲染农场'
          keywords='云渲染活动'
          description='Renderbus云渲染活动中心,云渲染充值优惠,折扣价格,行业竞赛等,瑞云渲染农场优惠活动'
        />
        {bannerList.length > 1 ? (
          <SwiperSwitch navBottom={30}>
            {bannerList.map((banner, index) => (
              <BannerContainer key={index}>
                {banner.image && (
                  <a href={banner.link} target='_blank' rel='noopener noreferrer'>
                    <Billboard
                      style={{ zIndex: 0 }}
                      fluid={[
                        banner.image[1].fluid,
                        {
                          ...banner.image[0].fluid,
                          media: `(min-width: 600px)`,
                        },
                      ]}
                    />
                  </a>
                )}
              </BannerContainer>
            ))}
          </SwiperSwitch>
        ) : (
          <>
            {bannerList.map((banner, index) => (
              <BannerContainer key={index}>
                {banner.image && (
                  <a href={banner.link} target='_blank' rel='noopener noreferrer'>
                    <Billboard
                      style={{ zIndex: 0 }}
                      fluid={[
                        banner.image[1].fluid,
                        {
                          ...banner.image[0].fluid,
                          media: `(min-width: 600px)`,
                        },
                      ]}
                    />
                  </a>
                )}
              </BannerContainer>
            ))}
          </>
        )}

        <Wrapper>
          <PromotionList spacing={2}>
            {activityList.map((activity, index) => {
              return (
                <ActivityBox key={index} xs='6' lg='4'>
                  <a href={activity.link}>
                    <Img fluid={activity.image.fluid} alt={activity.image.title} />
                    <p>{activity.description}</p>
                  </a>
                </ActivityBox>
              )
            })}
          </PromotionList>
        </Wrapper>
      </Layout>
    )
  }
}

export default Promotion

export const query = graphql`
  query {
    bannerInfo: allContentfulPromotionBanner(sort: { fields: sort, order: DESC }) {
      nodes {
        link
        sort
        image {
          title
          fluid(maxHeight: 600) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    activity: allContentfulActivity(sort: { fields: sort, order: DESC }) {
      nodes {
        link
        sort
        description
        image {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
